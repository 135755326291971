<template>
  <div class="marketing" style="width: 100%;" :style="`padding-bottom:${isMobile?'50px':'0'};`">
    <h1 class="heading">Marketing options</h1>
    <div class="event_header" style="margin-bottom:15px;width:100%;">
      <div style="display: flex;flex:1;margin-right: 15px" v-if="page==='list'">
        <v-select
            v-model="objectItem"
            hide-details
            :items="objects.list"
            item-value="id"
            item-text="name"
            return-object
            dense
            style="max-width: 300px"
            outlined
            @change="changeHotel"
        >
          <template v-slot:selection="{ item }">
            <div>{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
          <template v-slot:item="{ item }">
            <div>{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
        </v-select>
      </div>
      <div  style="flex:1">
        <v-btn v-if="page === 'list'" color="primary" outlined small @click="createGuestSegment"
        >+ Add new segment</v-btn
        >
        <v-btn v-if="page !== 'list'" style="flex:1" color="primary" outlined small @click="backToList"
        ><v-icon>mdi-chevron-left</v-icon> Back to list</v-btn
        >
      </div>
    </div>
    <div v-if="page === 'list'">
      <h2 class="constructor__heading" v-if="!isMobile" style="margin-bottom: 15px;">
        Guest Segments
      </h2>
      <div  style="display: flex;flex-wrap: wrap;width: 100%;">
        <div v-if="!segments.length">
          There is no segment you added
        </div>
        <GuestSegment @editSegment="editSegmentOpen" @deleteSegment="deleteSegmentOpen"  v-for="segment in segments" :key="segment.id" :segment="segment"/>
      </div>
    </div>
    <template v-if="page === 'create' && createSegment">
      <CreateGuestSegment :segment="createSegment" @createSuccess="createSuccess" />
    </template>
    <template v-if="page === 'edit' && editSegment">
      <EditGuestSegment :segment="editSegment" @editSuccess="editSuccess"/>
    </template>
    <PopupDelete ref="PopupDelete" @confirm="deleteSegmentConfirm" :title="`Are you sure about delete ${deleteSegmentModel?deleteSegmentModel.name:''}?`"/>
    <PopupSuccess ref="PopupSuccess" title="Success" :content="successMessage" />
  </div>
</template>

<script>
  import titleMixin from "../mixins/titleMixin";
  import {mapState,mapActions} from 'vuex'
  import store from "@/store"
  import GuestSegment from "../components/Marketing/GuestSegment";
  import CreateGuestSegment from "../components/Marketing/CreateGuestSegment";
  import PopupDelete from "../components/Popup/PopupDelete";
  import PopupSuccess from "../components/Popup/PopupSuccess";
  import EditGuestSegment from "../components/Marketing/EditGuestSegment";
  export default {
    components:{
      EditGuestSegment,
      PopupSuccess,
      PopupDelete,
      GuestSegment,
      CreateGuestSegment
    },
    name: "Marketing",
    mixins:[titleMixin],
    beforeRouteEnter(to, from, next) {
      if(!store.state.objects.list.length){
        store.dispatch('objects/getList')
          .then((data) => {
            next();
          }).catch(() => {
          next();
        })
      }else{
        next();
      }
    },
    title(){
      return 'Marketing'
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    data(){
      return {
        isMobile:false,
        successMessage:"",
        createSegment:null,
        editSegment:null,
        deleteSegmentModel:null,
        objectItem: null,
        page:'list',
        defaultSegment:{
          hotel_id:null,
          name:"",
          small_description:"",
          active:true,
          key:"",
          simple_keys:[],
          keys:{
            guest:{
              single:false,
              couple:false,
              group:false,
              childs:false,
              infant:false,
              dogs:false,
              cats:false,
            },
            booking_source:{
              custom_keys:[]
            },
            reservation_type:{
              room_number:[],
              room_prefix:[],
              day_in_week_from:{
                mon:false,
                tue:false,
                wed:false,
                thu:false,
                fri:false,
                sat:false,
                sun:false,
              },
              day_in_week_to:{
                mon:false,
                tue:false,
                wed:false,
                thu:false,
                fri:false,
                sat:false,
                sun:false,
              },

            },
            days_count:{
              less:"",
              equals:"",
              more:""
            },
            days_before_start:""
          }
        },
      }
    },
    computed:{
      ...mapState(['objects']),
      ...mapState('segments',['segments']),
    },
    methods:{
      ...mapActions('segments',['getSegments','deleteSegment']),
      editSuccess(){
        this.successMessage = 'Segment edited successfully';
        this.$refs.PopupSuccess.open();
      },
      createSuccess(){
        this.successMessage = 'New segment created successfully';
        this.page = 'list';
        this.$refs.PopupSuccess.open();
      },
      editSegmentOpen(segment){
        this.editSegment = JSON.parse(JSON.stringify(segment));
        this.page = 'edit';
      },
      deleteSegmentOpen(segment){
        this.deleteSegmentModel = JSON.parse(JSON.stringify(segment));
        this.$refs.PopupDelete.open();
      },
      async deleteSegmentConfirm(){
        await this.deleteSegment(this.deleteSegmentModel.id);
        this.successMessage = `Segment ${this.deleteSegmentModel?this.deleteSegmentModel.name:''} deleted successfully!`;
        this.$refs.PopupSuccess.open();
      },
      async changeHotel(){
        this.defaultSegment.hotel_id = this.objectItem.id;
        await this.getSegments(this.objectItem.id);
      },
      createGuestSegment(){
        this.createSegment = JSON.parse(JSON.stringify(this.defaultSegment));
        this.page = 'create';
      },
      backToList(){
        this.page = 'list';
      }
    },
    async mounted() {
      if (this.objects.list.length) {
        if (this.$route.query.id) {
          const candidate = this.objects.list.find(
            (x) => x.id === +this.$route.query.id
          );
          if (candidate) {
            this.objectItem = candidate;
          } else {
            this.objectItem = this.objects.list[0];
          }
        } else {
          this.objectItem = this.objects.list[0];
        }
      }
      this.defaultSegment.hotel_id = this.objectItem.id;
      await this.getSegments(this.objectItem.id);
    }
  }
</script>

<style lang="scss">
  .event_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .event__textfield {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 0;
  }

  .label {
    margin-bottom: 15px;
  }
  .date_time {
    display: flex;
    width: 100%;
  .date {
    width: 100%;
    margin-bottom: 15px;
  .label {
    display: block;
    margin-bottom: 15px;
  }
  }
  .time {
    width: 200px;
    margin-bottom: 15px;
  .label {
    display: block;
    margin-bottom: 15px;
  }
  }
  }
  .event__img {
    padding: 15px 0;
  .label {
    display: block;
    margin-bottom: 15px;
  }
  }
  .vue-dropzone > .dz-preview .dz-details {
    display: none;
  }
  .vue-dropzone > .dz-preview .dz-image {
    width: 120px;
    height: 120px;
  }
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    border-radius: 0;
  }
  .theme--light.v-picker__body{
    height:310px;
  }
  .v-picker--time .v-picker__title{
    height: 88px;
  }
  .tox.tox-tinymce{
    width:100%;
  }
  .tariff_obj {
    margin-left:5px;
    display: inline-block;
    padding: 4px 6px;
    text-transform: uppercase;
    border-radius: 0 5px 0 5px;
    background: #007db5;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    color: #fff;
  }
  .marketing .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev{
    display: none;
  }
</style>
